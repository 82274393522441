import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "艾商机管理",
    hidden: true,
    redirect: '/task/AIManagement',
    component: () => import("../views/HomeView.vue"),
    children: [
      {
        path: "/task/taskManagement",
        name: "任务管理",
        component: () => import("../components/TaskManagement/TaskManagement.vue"),
      },
      {
        path: "/task/myClue",
        name: "我的线索",
        component: () => import("../components/Myclue/Myclue.vue"),
      },
      {
        path: "/task/publicClue",
        name: "线索公海",
        component: () => import("../components/Publicclue/Publicclue.vue"),
      },
      {
        path: "/task/AIManagement",
        name: "艾商机管理",
        component: () => import("../components/AIManagement/AIManagement.vue"),
      },
      {
        path: "/seminal/enterprise",
        name: "单查企业",
        component: () => import("../components/enterprise/enterprise.vue"),
      },
      {
        path: "/seminal/shop",
        name: "单查店铺",
        component: () => import("../components/shop/shop.vue"),
      },
      {
        path: "/seminal/industryTemplate",
        name: "行业模版",
        component: () => import("../components/industryTemplate/industryTemplate.vue"),
      },
      {
        path: "/seminal/clueModeling",
        name: "线索建模",
        component: () => import("../components/clueModeling/clueModeling.vue"),
      },
      {
        path: "/seminal/maps",
        name: "地图搜索",
        component: () => import("../components/maps/maps.vue"),
      },
      {
        path: "/seminal/batchEnterprise",
        name: "批量查询企业",
        component: () => import("../components/batchEnterprise/batchEnterprise.vue"),
      },
      {
        path: "/seminal/unlocked",
        name: "已解锁线索",
        component: () => import("../components/unlocked/unlocked.vue"),
      },
      {
        path: "/seminal/myUnlocked",
        name: "已解锁线索",
        component: () => import("../components/myUnlocked/myUnlocked.vue"),
      },
      {
        path: "/seminal/dosage",
        name: "用量查看",
        component: () => import("../components/dosage/dosage.vue"),
      },
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  routes,
});

export default router;
