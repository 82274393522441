import axios from "axios";
import { Promise } from "core-js";
import { Message } from "element-ui";

// console.log(baseURL)

const service = axios.create({
  // baseURL: "http://43.139.246.247:8050", // baseURL会自动加在请求地址上 测试
  baseURL: "https://server.qijiee.com", // baseURL会自动加在请求地址上 正式
  // baseURL: "http://10.168.1.160:9001", // baseURL会自动加在请求地址上
  timeout: 10000, // 请求的超时毫秒数
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.code != 200) {
      Message({ message: response.data.msg || "error", type: "warning" });
    }
    return response
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
